window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
    require('admin-lte/plugins/icheck-bootstrap');
    require('moment/locale/tr');
    window.moment = require('moment');
    require('@fortawesome/fontawesome-free/js/all');
    // DATATABLES
    require('datatables.net/js/jquery.dataTables');
    require('datatables.net-bs4/js/dataTables.bootstrap4');
    require('datatables.net-autofill/js/dataTables.autoFill');
    require('datatables.net-autofill-bs4/js/autoFill.bootstrap4');
    require('datatables.net-buttons/js/dataTables.buttons');
    require('datatables.net-buttons/js/buttons.print');
    require('datatables.net-buttons/js/buttons.colVis');
    require('datatables.net-buttons/js/buttons.flash');
    require('datatables.net-buttons/js/buttons.html5');
    require('datatables.net-buttons-bs4/js/buttons.bootstrap4');
    require('datatables.net-colreorder/js/dataTables.colReorder');
    require('datatables.net-colreorder-bs4/js/colReorder.bootstrap4');
    require('datatables.net-fixedcolumns/js/dataTables.fixedColumns');
    require('datatables.net-fixedcolumns-bs4/js/fixedColumns.bootstrap4');
    require('datatables.net-fixedheader/js/dataTables.fixedHeader');
    require('datatables.net-fixedheader-bs4/js/fixedHeader.bootstrap4');
    require('datatables.net-keytable/js/dataTables.keyTable');
    require('datatables.net-keytable-bs4/js/keyTable.bootstrap4');
    require('datatables.net-responsive/js/dataTables.responsive');
    require('datatables.net-responsive-bs4/js/responsive.bootstrap4');
    require('datatables.net-rowgroup/js/dataTables.rowGroup');
    require('datatables.net-rowgroup-bs4/js/rowGroup.bootstrap4');
    require('datatables.net-rowreorder/js/dataTables.rowReorder');
    require('datatables.net-rowreorder-bs4/js/rowReorder.bootstrap4');
    require('datatables.net-scroller/js/dataTables.scroller');
    require('datatables.net-scroller-bs4/js/scroller.bootstrap4');
    require('datatables.net-select/js/dataTables.select');
    require('datatables.net-select-bs4/js/select.bootstrap4');
    require('admin-lte/plugins/pdfmake/pdfmake');
    require('admin-lte/plugins/pdfmake/vfs_fonts');
    require('admin-lte/plugins/jszip/jszip');
    require('admin-lte/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4');
    // REMIX ICONS
    require('remixicon/fonts/remixicon.css');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

$(function () {
    $('.date').datetimepicker({
        locale: 'tr',
        icons: {
            time: "fas fa-clock",
            date: "fas fa-calendar",
            up: "fas fa-arrow-up",
            down: "fas fa-arrow-down"
        }
    });
});